$(document).ready(
  function(){
    $('#category_id').change(function(){
      $(this).find("option:selected").each(function(){
        categoryValue = $('#category_id :selected').text();
        if(categoryValue){
          console.log(categoryValue)
          $(".amenities").not("." + categoryValue).hide();
          $("." + categoryValue).show();
        } else {
          $(".amenities").hide();
        }
      });
    });
    var category_types;
    category_types = $('#category_type_id').html();
    return $('#category_id').change(function(){
      var category, options;
      category = $('#category_id :selected').text();
      options = $(category_types).filter("optgroup[label=" + category + "]").html();
      if (options) {
        return $('#category_type_id').html(options);
      } else {
        return $('#category_type_id').empty();
      }
    });
  }
);
